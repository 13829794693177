<template>
  <div class="welcome-container">
    <div class="search">
      <div>
        <span style="color: #606266; font-size: 14px;margin-right: 12px;">赛季</span>
        <el-select
            placeholder="请选择"
            style="width: 240px;margin-right: 12px;"
            v-model="season"
        >
            <el-option
                v-for="item in dict_season"
                :key="item.dictCode"
                :label="item.dictName"
                :value="item.dictValue"
            ></el-option>
        </el-select>
        <el-button type="primary" @click="getData">查询</el-button>
      </div>
      <el-button type="primary" plain @click="showExport">导出数据</el-button>
    </div>
    <el-row :gutter="20">
      <el-col :span="24" v-for="(item, index) of listData" :key="index">
        <div class="mycard">
          <div class="title222">{{item.title}}</div>
          <div class="box-data" v-if="item.boxData && item.boxData.length">
            <el-row :gutter="20">
              <el-col :span="6" v-for="(bItem,m) of item.boxData" :key="m">
                <div class="box-data-item">
                  <div><i class="el-icon-s-data"></i>{{ bItem.title }}</div>
                  <div class="num">{{ bItem.num }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- <div class="mycard-h2"></div> -->
          <div class="echarts" :id="'mychart' + index"></div>
      </div>
      </el-col>
    </el-row>
    <el-dialog
      title="导出数据"
      :visible.sync="visible"
      width="30%">
      <el-form
        label-width="80px"
        ref="formRef"
        :rules="formRules"
        :model="form"
      >
        <el-form-item label="赛季" prop="season">
          <el-select
            placeholder="默认当前赛季"
            style="width: 100%"
            v-model="form.season"
          >
            <el-option
              v-for="item in dict_season"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赛区" prop="districtIdList">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            multiple
            v-model="form.districtIdList"
            clearable
          >
            <el-option
              v-for="item in dict_district"
              :key="item.id"
              :label="item.districtName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeExport">取 消</el-button>
        <el-button type="primary" @click="exportFn">导 出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { queryDistrictPage } from '@/http/api'
export default {
  data () {
    return {
      season: '2024', // this.$chnEngStatusCode.defaultSeason,
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_district: [],
      listData: [
        {
          title: '城市初选',
          key: 'preliminaryTotalNum',
          type: 2, // 展示几个数据
          boxData: [
            {
              title: '参赛人数',
              key: 'preliminaryTotalNum',
              num: 0
            },
            {
              title: '缴费人数',
              key: 'preliminaryPayNum',
              num: 0
            },
            {
              title: '线上参赛人数',
              key: 'preliminaryOnlineNum',
              num: 0
            },
            {
              title: '线下参赛人数',
              key: 'preliminaryOfflineNum',
              num: 0
            }
          ],
          legendData: ['参赛人数', '缴费人数'],
          list: []
        },
        {
          title: '城市复选',
          key: 'intermediaryTotalNum',
          type: 2, // 展示几个数据
          boxData: [
            {
              title: '参赛人数',
              key: 'intermediaryTotalNum',
              num: 0
            },
            {
              title: '缴费人数',
              key: 'intermediaryPayNum',
              num: 0
            },
            {
              title: '线上参赛人数',
              key: 'intermediaryOnlineNum',
              num: 0
            },
            {
              title: '线下参赛人数',
              key: 'intermediaryOfflineNum',
              num: 0
            }
          ],
          legendData: ['参赛人数', '缴费人数'],
          list: []
        },
        {
          title: '初转复转化率(%)',
          key: 'rate',
          legendData: ['转化率(%)'],
          list: []
        },
        {
          title: '城市半决选',
          key: 'cityHalfTotalNum',
          legendData: ['参赛人数'],
          list: []
        },
        {
          title: '城市总决选',
          key: 'cityFinalsTotalNum',
          legendData: ['参赛人数'],
          list: []
        },
        {
          title: '全国总决选',
          key: 'nationalFinalsGroupTotalNum',
          boxData: [
            {
              title: '全国总决选总参赛人数',
              key: 'nationalFinalsGroupTotalNum',
              num: 0
            }
          ],
          legendData: ['参赛人数'],
          list: []
        },
        {
          title: '网络总决选',
          key: 'webFinalsTotalNum',
          legendData: ['参赛人数'],
          list: []
        }
      ],
      xAxisArr: [],
      // 柱状图设置
      barSetting: {
        type: 'bar',
        barCategoryGap: '10%', // 类目间隔设为类目宽度的10%
        barGap: '50%', // 系列间的间隔设为类目宽度的80%
        label: {
          show: true,
          position: 'top',
          textStyle: {
            fontSize: '12px',
            color: '#999'
          }
        },
        barWidth: 16
      },
      visible: false,
      form: {
        season: this.$chnEngStatusCode.defaultSeason,
        districtIdList: [],
        applyStatus: []
      },
      formRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        districtIdList: [{ required: true, message: '请选择', trigger: 'change' }],
        applyStatus: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$http.get('enrollment/statistics', { params: { season: this.season } }).then(res => {
        if (res.code === 200) {
          // 1、横坐标
          const obj = res.data.considerationList
          const arr = Object.keys(obj).map(key => {
            return obj[key]
          })
          const newArr = this.flattenArray(arr)
          this.xAxisArr = newArr.map(item => item.districtName)
          //
          this.listData.forEach((item, index) => {
            // 2、外层数据展示
            if (item.boxData) {
              item.boxData.forEach(bItem => {
                bItem.num = res.data[bItem.key]
              })
            }
            // 3、y轴数据提取
            // console.log(item, newArr)
            if (item.type === 2) {
              if (item.key === 'preliminaryTotalNum') {
                // 初赛
                // 参赛人数
                const yDataArr1 = newArr.map(yitem => yitem.preliminaryTotalNum)
                const seriesData1 = { ...{ name: '参赛人数', data: yDataArr1 }, ...this.barSetting }
                // 缴费人数
                const yDataArr2 = newArr.map(yitem => yitem.preliminaryPayNum)
                const seriesData2 = { ...{ name: '缴费人数', data: yDataArr2 }, ...this.barSetting }
                const preliminarySeriesData = []
                preliminarySeriesData[0] = seriesData1
                preliminarySeriesData[1] = seriesData2
                setTimeout(() => {
                  this.initChart(index, item.legendData, preliminarySeriesData)
                })
              } else if (item.key === 'intermediaryTotalNum') {
                // 复赛
                // 参赛人数
                const yDataArr1 = newArr.map(yitem => yitem.intermediaryTotalNum)
                const seriesData1 = { ...{ name: '参赛人数', data: yDataArr1 }, ...this.barSetting }
                // 缴费人数
                const yDataArr2 = newArr.map(yitem => yitem.intermediaryPayNum)
                const seriesData2 = { ...{ name: '缴费人数', data: yDataArr2 }, ...this.barSetting }
                const intermediarySeriesData = []
                intermediarySeriesData[0] = seriesData1
                intermediarySeriesData[1] = seriesData2
                setTimeout(() => {
                  this.initChart(index, item.legendData, intermediarySeriesData)
                })
              }
            } else {
              if (item.key === 'rate') {
                const yDataArr = newArr.map(yitem => yitem[item.key] * 100)
                // console.log(yDataArr)
                const seriesData = { ...{ name: '转化率(%)', data: yDataArr }, ...this.barSetting }
                setTimeout(() => {
                  this.initChart(index, item.legendData, seriesData)
                })
              } else {
                const yDataArr = newArr.map(yitem => yitem[item.key])
                // console.log(yDataArr)
                const seriesData = { ...{ name: '参赛人数', data: yDataArr }, ...this.barSetting }
                setTimeout(() => {
                  this.initChart(index, item.legendData, seriesData)
                })
              }
            }
          })
        }
      })
    },
    // 二维转一维
    flattenArray (arr) {
      return [].concat.apply([], arr)
    },
    /**
     * 绘制柱状图
     * @param {*} index 对应循环中index，用于id
     * @param {*} legendData 图例赛区数组，例如：['天津', '北京']
     * @param {*} seriesData y轴数据[Array],以赛区为单位
     */
    initChart (index, legendData, seriesData) {
      const myChart = echarts.init(document.getElementById('mychart' + index))
      const option = {
        grid: {
          left: 60,
          right: 60
        },
        legend: {
          data: legendData, // 图例名称
          left: 'center',
          itemWidth: 20,
          itemHeight: 12, // 修改icon图形大小
          orient: 'horizontal',
          textStyle: { // 图例文字的样式
            color: '#a1a1a1', // 图例文字颜色
            fontSize: 12 // 图例文字大小
          }
        },
        tooltip: {},
        color: ['#409eff', '#91cc75'],
        xAxis: {
          type: 'category',
          data: this.xAxisArr,
          axisLabel: {
            color: '#000',
            interval: 0,
            fontSize: 10,
            rotate: 25 // 设置倾斜角度25°
          }

        },
        yAxis: {
          minInterval: 1 // 最小刻度是1（y轴人数不能显示小数）
        },
        series: seriesData
      }
      myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    },
    handleTo (path) {
      if (path) {
        this.$router.push(path)
      }
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    showExport () {
      this.getDistrictList()
      this.visible = true
    },
    closeExport () {
      this.visible = false
      this.$refs.formRef.resetFields()
    },
    exportFn () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const str0 = `season=${this.form.season}`
        const str1 = this.form.districtIdList.map(id => {
          return `districtIdList=${id}`
        }).join('&')
        const downLoadUrl = `${this.$env.baseIP}enrollment/exportStatistics?${str0}&${str1}`
        this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '赛区考量-导出')
      })
    }
  }
}
</script>
<style scoped lang="less">
.welcome-container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.mycard{
  background-color: #fff;
  border-radius: 6px;
  padding-top: 20px;
  margin-bottom: 20px;
  .title_row{
    margin-left: 20px;
  }
}
.national{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .panel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    background-color: #fff;
    flex: 1;
    padding: 16px 24px;
    padding-left: 40px;
    font-size: 14px;
    border-radius: 6px;
    &:last-child{
      margin-right: 0;
    }
    .title{
      color: #a1a1a1;
    }
    .num{
      font-size: 32px;
      font-family: hzgb;
      margin-top: 10px;
    }
    .img1{
      height: 40px;
    }
    .img2{
      height: 50px;
    }
    .img3{
      height: 34px;
    }
  }
  .panel0{
    border-left: 6px solid #409eff;
  }
  .panel1{
    border-left: 6px solid #FF8001;
  }
  .panel2{
    border-left: 6px solid #4EC61D;
  }
}
.search{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.echarts{
  width: 100%;
  height: 300px;
}
.table{
  // width: 100%;
  border-top: 1px solid #dcdfe6;
  display: flex;
  // align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  font-size: 14px;
  color: #606266;
  .tr{
    flex: 1;
    width: 100%;
  }
  .td{
    border-bottom: 1px solid #dcdfe6;
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .td1{
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
  .td2{
    color: #409eff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .tr1 .td{
    text-align: left;
  }
}
.mycard-h2{
  color: #000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 12px;
}
.title222{
  display: inline-block;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-left: 4px;
  padding-right: 20px;
  padding-bottom: 2px;
  border-bottom: 2px solid #409eff;
  font-style: italic;
}
.el-icon-question{
  color: #409eff;
  font-size: 18px;
}

.box-data{
  padding: 0 20px 20px;
  .box-data-item{
    font-size: 14px;
    background-color: #f3f9ff;
    border-radius: 10px;
    padding: 12px 20px;
    color: #a5a6a7;
    .num{
      font-size: 28px;
      color: #000;
      margin-left: 24px;
      margin-top: 4px;
    }
    .el-icon-s-data{
      color: #409eff;
      font-size: 20px;
      margin-right: 4px;
    }
  }
}
</style>
